import {supportedLanguages} from "@/util/helper";


export const SET_LANGUAGES = "SET_LANGUAGES";

const transformLanguages = (languageList) => {
    return languageList.filter(language => supportedLanguages.find(supportedLanguage => supportedLanguage === language.code.toLowerCase())).map(language => {
        return {
            ...language, code: language.code.toLowerCase()
        }
    });
};

export default function (state = null, action) {
    if (action.type === SET_LANGUAGES) {
        return transformLanguages(action.languages);
    }
    return state;
}

export const setLanguages = (languages) => ({
    type: SET_LANGUAGES,
    languages,
});
