export const SET_COMPANIES = 'SET_COMPANIES'

export default function (state = { companies: [] }, action) {
    if (action.type == SET_COMPANIES) {
        return {
            ...state,
            companies: action.companies
        }
    }
    return state;
}


export const setCompanies = (companies) => ({
    type: SET_COMPANIES,
    companies
})

