import { combineReducers } from "redux";
import user from "@/redux/user";
import currencies from "@/redux/currencies";
import languages from "@/redux/languages";
import common from "@/redux/common";
import selectedCompany from "@/redux/selectedCompany";
import companyCurrency from "@/redux/companyCurrency";

export default () =>
  combineReducers({
    user,
    currencies,
    languages,
    common,
    selectedCompany,
    companyCurrency,
  });

export type ProjectState = {
  user: UserState,
  currencies: CurrenciesState,
  languages: any,
  common: any,
  selectedCompany: any,
  companyCurrency: CurrencyResponse,
};
