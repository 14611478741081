import axios, { AxiosInstance } from "axios";
import getConfig from "next/config";
import cookies from "next-cookies";
import { getSafeDeep } from "@/util/state";
import axiosBetterStacktrace from "axios-better-stacktrace";
import { getCookie } from "@/util/helper";
import { PAYOUTS_TOKEN } from "@/util/constants";

const { publicRuntimeConfig } = getConfig();

let apiClient;
let middlewareApiClient;

const csrfCookieName = process.env.csrfCookieName || "csrftoken";
const csrfHeaderName = process.env.csrfHeaderName || "X-CSRFToken";
// const frontendHost = process.env.SITE_HOST || "https://pokvareno.com";

const createApiClient = () => {
  const client = axios.create({
    baseURL: publicRuntimeConfig.backendApiHost,
    withCredentials: true,
    xsrfCookieName: csrfCookieName,
    xsrfHeaderName: csrfHeaderName,
  });
  axiosBetterStacktrace(client);
  return client;
};

const createMiddlewareApiClient = () =>
  axios.create({
    baseURL: publicRuntimeConfig.frontendHost,
    withCredentials: true,
    xsrfCookieName: csrfCookieName,
    xsrfHeaderName: csrfHeaderName,
  });

/**
 * Get axios client instance
 * @returns {AxiosInstance}
 */
export const getApiClient = (ctx): AxiosInstance => {
  if (ctx && ctx.req) {
    // eslint-disable-next-line no-shadow
    const apiClient = createApiClient();
    apiClient.interceptors.request.use(
      (request) => {
        if (ctx.req.headers.cookie) {
          request.headers.Cookie = ctx.req.headers.cookie;
          const csrfCookie = cookies(ctx)[csrfCookieName];
          if (csrfCookie) {
            request.headers[csrfHeaderName] = csrfCookie;
          }
        }
        // request.headers.common['Accept-Language'] = undefined;
        // delete request.headers.common["Accept-Language"];
        return request;
      },
      (error) => {
        log(error);
        throw error;
      }
    );
    apiClient.interceptors.response.use(
      (response) => {
        if (response.headers["Set-Cookie"]) {
          ctx.res.setHeader("Set-Cookie", response.headers["Set-Cookie"]);
        }
        return response;
      },
      (error) => {
        log(
          `HTTP Failed: ${getSafeDeep(
            error,
            "request.method",
            "Axios http fail fail."
          )} ${getSafeDeep(error, "request.path", "Axios http fail fail.")}`
        );
        if (error.response) {
          log(error.response.data);
        } else {
          log(error);
        }
        throw error;
      }
    );
    return apiClient;
  }
  if (!apiClient) {
    apiClient = createApiClient();
  }
  return apiClient;
};

/**
 * Get middleware axios client instance
 * @returns {AxiosInstance}
 */
export const getMiddlewareApiClient = (ctx): AxiosInstance => {
  if (ctx && ctx.req) {
    // eslint-disable-next-line no-shadow
    const middlewareApiClient = createMiddlewareApiClient();
    middlewareApiClient.interceptors.request.use(
      (request) => {
        if (ctx.req.headers.cookie) {
          request.headers.Cookie = ctx.req.headers.cookie;
          const csrfCookie = cookies(ctx)[csrfCookieName];
          if (csrfCookie) {
            request.headers[csrfHeaderName] = csrfCookie;
          }
        }
        return request;
      },
      (error) => {
        log(error);
        throw error;
      }
    );
    middlewareApiClient.interceptors.response.use(
      (response) => {
        if (response.headers["Set-Cookie"]) {
          ctx.res.setHeader("Set-Cookie", response.headers["Set-Cookie"]);
        }
        return response;
      },
      (error) => {
        log(
          `HTTP Failed: ${getSafeDeep(
            error,
            "request.method",
            "Axios http fail fail."
          )} ${getSafeDeep(error, "request.path", "Axios http fail fail.")}`
        );
        if (error.response) {
          log(error.response.data);
        } else {
          log(error);
        }
        throw error;
      }
    );
    return middlewareApiClient;
  }
  if (!middlewareApiClient) {
    middlewareApiClient = createMiddlewareApiClient();
  }
  return middlewareApiClient;
};

function log(message) {
  if (process.env.NODE_ENV !== "production") {
    console.log(message);
  }
}

const createApiPayoutsClient = () => {
  const client = axios.create({
    baseURL: publicRuntimeConfig.payoutsApiHost,
    // withCredentials: true,
  });
  axiosBetterStacktrace(client);
  return client;
};

export const getApiPayoutsClient = (): AxiosInstance => {
  const apiPayoutsClient = createApiPayoutsClient();

  apiPayoutsClient.interceptors.request.use(
    async (config) => {
      const token = await getCookie(PAYOUTS_TOKEN);

      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
        "Content-Type": "application/json",
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return apiPayoutsClient;
};
