export function getSafeDeep(object, path, undefinedValue=""){
    let currentObject = object;
    const pathStrings = path.split(".");

    for(let pathString of pathStrings) {
        if (currentObject === null || currentObject === undefined) return undefinedValue;
        currentObject = currentObject[pathString];
    }

    if (currentObject === null || currentObject === undefined) return undefinedValue;
    return currentObject;
}

export function arrayToPairs(array) {
    let newArray = [];
    for(let i = 0; i < array.length; i += 2) {
        if (i < array.length - 1) {
            newArray.push([array[i], array[i+1]]);
        } else {
            newArray.push([array[i]]);
        }
    }
    return newArray;
}

export function getSafeDeepByKey(targetObject, keyObject, keyPath, undefinedValue="") {
    const key = getSafeDeep(keyObject, keyPath, undefined);
    if (key === undefined) return undefinedValue;
    return getSafeDeep(targetObject, key, undefinedValue);
}

export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}


export function trimNullValues(object) {
    Object.keys(object).forEach((key) => {
        if (object[key] === null) {
            delete object[key];
        } else {
            if (typeof(object[key]) === 'object') trimNullValues(object[key]);
        }
    });
}


export function trimEmptyStrings(object) {
    Object.keys(object).forEach((key) => {
        if (object[key] === "") {
            delete object[key];
        } else {
            if (typeof(object[key]) === 'object') trimEmptyStrings(object[key]);
        }
    });
}


export function trimUrlFields(object) {
    Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'string' && object[key].indexOf('http') === 0) {
            delete object[key];
        } else {
            if (typeof(object[key]) === 'object' && object[key] !== undefined && object[key] !== null) trimUrlFields(object[key]);
        }
    });
}

export function unsetValuesToEmptyStrings(object) {
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined || object[key] === null) {
            object[key] = "";
        } else {
            if (typeof(object[key]) === 'object') unsetValuesToEmptyStrings(object[key]);
        }
    });
};


export function trimEmptyObjects(object) {
    for (let k in object) {
        if (!object[k] || typeof object[k] !== "object") {
            continue // If null or not an object, skip to the next iteration
        }

        // The property is an object
        trimEmptyObjects(object[k]); // <-- Make a recursive call on the nested object
        if (Object.keys(object[k]).length === 0) {
            delete object[k]; // The object had no properties, so delete that property
        }
    }
}


export function parseErrors(errorResponse){
    return mapBackendErrors(JSON.parse(errorResponse.data.message));
}

export const mapBackendErrors = (errorList) => {
    let errorMap = {};
    errorList.forEach(item => {
        item.fields.forEach(subItem => {
            let key = subItem;
            if(subItem.indexOf("working_hours") != -1){
                key = subItem.replace("working_hours","working_hours_transformed");
                key = key.split(".");
                key.pop();
                key = key.join(".");
            }
            // key = key.replace(".","/properties/");
            // const fullKey = `#/properties/form/properties/${key}`;
            if(!errorMap[key]) errorMap[key] = item.reason;
        });
    });
    return errorMap;
};



export function deepCopy(object, keepUndefined = false) {
    if (keepUndefined) return JSON.parse(JSON.stringify(object, (k,v) => v === undefined ? null : v));
    return JSON.parse(JSON.stringify(object));
}

export function swap(a,b) {
    const t = a;
    a = b;
    b = t;
}


export function isUrl(string) {
    return string.indexOf("http") === 0;
}
