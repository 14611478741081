import getConfig from "next/config";
import { getApiClient, getApiPayoutsClient } from "@/api/util";
import type { User } from "@/api/auth/model";
import axios, { AxiosPromise } from "axios";
import { getCurrentLanguage } from "@/util/helper";

const { publicRuntimeConfig } = getConfig();

export default (ctx) => ({
  getUser: (): AxiosPromise<User> => getApiClient(ctx).get(`/v2/auth`),
  getSettings: (lang): AxiosPromise<Object> =>
    getApiClient(ctx).get(`/v2/user/settings?lng_code=${lang}`, {
      // headers: {
      //     'Language': lang
      // }
    }),
  login: (
    username: string,
    password: string,
    remember = false
  ): AxiosPromise<Object> => {
    const body = {
      username,
      password,
    };
    if (remember) body.remember_me = true;
    return getApiClient(ctx).post(`/v2/auth`, body);
  },
  getRole: (): AxiosPromise<Object> =>
    getApiClient(ctx).get("/v3/rbac/user/current/roles"),
  changePassword: (old_password, new_password): AxiosPromise<Object> =>
    getApiClient(ctx).post("/v3/company/password/change", {
      old_password,
      new_password,
    }),
  logout: (): AxiosPromise => getApiClient(ctx).delete("/v2/auth"),
  resetPassword: (email): AxiosPromise =>
    getApiClient(ctx).post(
      `/v3/lab4pay/company/password/reset`,
      {
        email,
      },
      {
        headers: { "Accept-Language": getCurrentLanguage() },
      }
    ),
  verifyPasswordResetToken: (email, token): AxiosPromise =>
    getApiClient(ctx).post(`/v3/company/password/verify-reset-token/`, {
      email,
      token,
    }),
  setNewPassword: (email, token, password, lng = "en"): AxiosPromise =>
    getApiClient(ctx).post(
      `/v3/lab4pay/company/password/new/${token}/`,
      {
        new_password: password,
        confirm_password: password,
        email,
      },
      {
        headers: { "Accept-Language": getCurrentLanguage() },
      }
    ),
  verifyAccountBlockToken: (email, token): AxiosPromise =>
    getApiClient(ctx).post(`/v3/company/password/verify-reset-token/`, {
      email,
      token,
    }),
  blockAccount: (token) => getApiClient(ctx).post(`/v3/login/block/${token}`),
  cancelAccountBlock: (token) =>
    getApiClient(ctx).post(`/v3/block/cancel/${token}`),
  authPayouts: () =>
      axios.post(
          "/auth/token/",
          {
              grant_type: "client_credentials",
              client_id: publicRuntimeConfig.payoutsClientId,
              client_secret: publicRuntimeConfig.payoutsClientSecret,
          },
          {
              headers: {
                  Accept: "*/*",
                  "Content-Type": "application/json",
              },
              baseURL: publicRuntimeConfig.payoutsApiHost,
          }
      ),
});
