import type { CurrencyResponse } from "@/api/organization/model";

export const SET_COMPANY_CURRENCY = "SET_COMPANY_CURRENCY";

export default function (state: CompanyCurrencyState = {
    id: 0,
    symbol: "EUR",
    display_symbol: "€",
}, action) {
    if (action.type === SET_COMPANY_CURRENCY) {
        return action.currency;
    }
    return state;
}

export const setCompanyCurrency = (currency: CurrencyResponse) => ({
    type: SET_COMPANY_CURRENCY,
    currency,
});

export type CompanyCurrencyState = CurrencyResponse;
