import getConfig from "next/config";
import i18n from "@/i18n";
import RouterNext from "next/router";
import type { NextRouter } from "next/router.d.ts";
import i18Next from "i18next";
import localforage from "localforage";

import cash from "@/assets/payment-method/cash.svg";
import mastercard from "@/assets/payment-method/mastercard.svg";
import other from "@/assets/payment-method/other-method.svg";
import visa from "@/assets/payment-method/visa.svg";
import diners from "@/assets/payment-method/diners.svg";
import goc from "@/assets/payment-method/goc.svg";
import mbills from "@/assets/payment-method/mbills.svg";
import maestro from "@/assets/payment-method/maestro.svg";
import valu from "@/assets/payment-method/valu.svg";
import jcb from "@/assets/payment-method/jcb.svg";
import layBuy from "@/assets/payment-method/laybuy.svg";
import unionPay from "@/assets/payment-method/union-pay.svg";
import alipay from "@/assets/payment-method/alipay.svg";
import wechat from "@/assets/payment-method/wechat.svg";

import { getApiClient } from "@/api/util";
import { getSafeDeep } from "@/util/state";
import moment from "moment";

const { publicRuntimeConfig } = getConfig();

export const getCurrentLanguage = () => i18Next.language || "en";

export const { withTranslation, useTranslation, appWithTranslation } = i18n;

export const redirectNewTab = (url) => {
  if (window) window.open(url, "_blank");
};

export const eraseCookie = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
};

export const getCookie = (name) => {
  const pairs = document.cookie.split(";");
  const pair = pairs.find((cookie) => cookie.split("=")[0].trim() === name);
  if (!pair) return "";
  return pair.split("=")[1];
};

export const setCookie = (name, value, domain) => {
  if (domain) {
    document.cookie = `${name}=${value};domain=${publicRuntimeConfig.cookieDomain};path=/`;
  } else {
    document.cookie = `${name}=${value}`;
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

export async function pollingReport(ref, sessionId, disabled) {
  const { data } = await getApiClient().get(
    `/api/v3/reports/status/?session_id=${sessionId}`
  );

  if (getSafeDeep(data, "status") === 4) {
    disabled(false);
    clearInterval(ref.current);
    window.open(getSafeDeep(data, "link", "_blank"));
  }
}

// formatting Comma Dat => 1,000.00 Antigua and Barbuda
// formatting Dot Comma => 1.000,00 DE
// currency.symbol property in currency
const commaDotCurrencies = [
  "XCD",
  "GHS",
  "KES",
  "NGN",
  "MXN",
  "INR",
  "ILS",
  "JPY",
  "MYR",
  "KRW",
  "LKR",
  "THB",
  "GBP",
  "AUD",
  "NZD",
];

export const parse = (currency = "EUR", number, toFixed = 0) => {
  if (commaDotCurrencies.includes(currency)) {
    return parseFloat(number).toLocaleString("en-US", {
      minimumFractionDigits: toFixed,
      maximumFractionDigits: toFixed,
    });
  }
  return parseFloat(number).toLocaleString("de-DE", {
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed,
  });
};

export const getDecimalDeliminator = (currency) => {
  const dot = parse(currency, 0, 2).split("").includes(".");
  return dot ? "." : ",";
};

export const getThousandsDeliminator = (currency) => {
  const decimal = getDecimalDeliminator(currency);
  return decimal == "." ? "," : ".";
};

export const getPaymentMethodIcon = (name) => {
  const icons = {
    Cash: cash,
    Mastercard: mastercard,
    Visa: visa,
    "Diners Club": diners,
    GoCrypto: goc,
    mBills: mbills,
    Maestro: maestro,
    Valu: valu,
    JCB: jcb,
    UnionPay: unionPay,
    Other: other,
    Card: other,
    GOCRYPTO: goc,
    VALU: valu,
    ALIPAY: alipay,
    WECHAT: wechat,
    LAYBUY: layBuy,
  };

  return icons[name];
};

export const scrollToFaulty = (ref, event) => {
  const formLength = ref.current.length;

  let isFirst = true;
  for (let i = 0; i < formLength; i++) {
    const elem = ref.current[i];
    if (!elem.validity.valid) {
      // TODO: refactor this hackaround - issue with HTML5 form validation relative jump and sticky header
      // Timeout will hang - sacrificed to make reuse easier
      if (isFirst && elem == event.target) {
        setTimeout(() => {
          window.scrollBy(0, -100);
        }, 50);
      }
      isFirst = false;
    }
  }
};

export const scrollToFaultyElement = (ref, event) => {
  const elem = event.target;
  if (ref.current === false) {
    setTimeout(() => {
      window.scrollBy(0, -150);
      ref.current = false;
    }, 50);
  }
  ref.current = true;
};

export const getFAQLink = () => {
  const fqs = {
    en: "https://web.gocrypto.com/hc/en-us/sections/360011183091-elly-POS",
    sl: "https://web.gocrypto.com/hc/sl/sections/360011183091-Elly-POS-",
    ro: "https://web.gocrypto.com/hc/ro-ro/sections/360011183091-elly-POS",
  };

  return fqs[getCurrentLanguage()];
};

export function downloadPDF(contents, type, name) {
  localforage
    .setItem(`download-${type}`, contents)
    .then((image) => {
      const blob = new Blob([image], { type: "application/png" });
      const pdfURI = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");

      downloadLink.href = pdfURI;
      downloadLink.download = `${name}.pdf`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch((err) => {
      // This code runs if there were any errors
      console.log(err);
    });
}

export function getDateFormatByType(type, from, to) {
  switch (type) {
    case "DAILY":
      return moment(from).format("D MMM YYYY");
    case "WEEKLY":
      return `${moment(from).format("D")} - ${moment(to).format("D MMM YYYY")}`;
    case "MONTHLY":
      return `${moment(from).format("D MMM YYYY")} - ${moment(to).format(
        "D MMM YYYY"
      )}`;
    default:
      return null;
  }
}

export const Router: NextRouter = RouterNext;

export const supportedLanguages = ["en", "sl", "ro"];
export const supportedReportLanguages = ["en", "sl"];
export const supportedLegalLanguages = ["en", "sl"];
